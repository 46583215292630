import { SET_CRITICAL_CSS, PAGE_META, SET_CRITICAL_CSS_IN_CACHE } from 'client/actions/constants';
import { isObject } from 'client/utils/object';
import { logger } from 'client/utils/isomorphic-logger';

export const MAX_CRITICAL_CSS_KEYS = 8;

function prepareCriticalCss(curState, loadedFromLocalCache) {
  if (!curState) return false;

  if (isObject(curState)) {
    return {
      ...curState,
      loadedFromLocalCache,
    };
  }

  return { loadedFromLocalCache };
}

export function serverReducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_CRITICAL_CSS: {
      let criticalCss = action.config;
      const loadedFromLocalCache = state?.criticalCss?.loadedFromLocalCache;

      if (isObject(criticalCss)) {
        const { criticalCssHeight, ...keyHash } = criticalCss;
        const keys = Object.keys(keyHash);
        if (keys.length > MAX_CRITICAL_CSS_KEYS) {
          logger('error', new Error(`too many critical css keys - ${JSON.stringify(criticalCss)}`));
          criticalCss = false;
        } else {
          criticalCss = {
            key: keys.map(key => `${key}:${keyHash[key]}`).join(','),
            height: criticalCssHeight,
          };
        }
      }

      const newCriticalCss = prepareCriticalCss(criticalCss, loadedFromLocalCache);

      return {
        ...state,
        criticalCss: newCriticalCss,
      };
    }
    case SET_CRITICAL_CSS_IN_CACHE: {
      const { payload } = action;
      const { criticalCss } = state;

      const newCriticalCss = prepareCriticalCss(criticalCss, payload);

      // require for skipping not require state update
      if (!criticalCss) return state;

      return { criticalCss: newCriticalCss };
    }
    case PAGE_META: {
      return { ...state, ...action.server };
    }
    default:
      return state;
  }
}
